import Obj1 from '@/assets/img/for-every-child/wherewework_obj1@2x.png';
import Obj2 from '@/assets/img/for-every-child/wherewework_obj2@2x.png';
import Obj3 from '@/assets/img/for-every-child/wherewework_obj3@2x.png';
import Obj4 from '@/assets/img/for-every-child/wherewework_obj4@2x.png';
import Obj5 from '@/assets/img/for-every-child/wherewework_obj5@2x.png';
import Obj6 from '@/assets/img/for-every-child/wherewework_obj6@2x.png';
import Container from '@/components/Container';
import MainTabButton from '@/components/MainTabButton';
import PageComponent from '@/components/PageComponent';
import ProcessList from '@/components/ProcessList';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import WorkTab1 from '@/page-blocks/what-we-do/for-every-child/WorkTab1';
import WorkTab2 from '@/page-blocks/what-we-do/for-every-child/WorkTab2';
import WorkTab3 from '@/page-blocks/what-we-do/for-every-child/WorkTab3';
import { PageProps } from 'gatsby';
import React, { FC, ReactElement, useState } from 'react';
import styled from 'styled-components';

const tabTitle = [`도움을 받는 나라`, `유니세프 본부`, `도움을 주는 나라`];
const tab: { [key: number]: ReactElement } = {
  0: <WorkTab1 />,
  1: <WorkTab2 />,
  2: <WorkTab3 />,
};

const countryData = [
  {
    id: 1,
    title: `약 190개 나라와 영토`,
    img: Obj1,
    desc: [
      {
        dec: `유니세프 활동 국가 및 영토`,
      },
    ],
  },
  {
    id: 2,
    title: `158개국`,
    img: Obj2,
    desc: [
      {
        dec: `도움을 받는 나라`,
      },
    ],
  },
  {
    id: 3,
    title: `33개국`,
    img: Obj3,
    desc: [
      {
        dec: `도움을 주는 나라`,
      },
    ],
  },
  {
    id: 4,
    title: `6개국`,
    img: Obj4,
    desc: [
      {
        dec: `구호물품 물류센터`,
      },
    ],
  },
  {
    id: 5,
    title: `약 15,000명 이상`,
    img: Obj5,
    desc: [
      {
        dec: `유니세프 직원 수`,
      },
    ],
  },
  {
    id: 6,
    title: `85%`,
    img: Obj6,
    desc: [
      {
        dec: `개발도상국 근무 직원`,
      },
    ],
  },
];

const SectionTop = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 0;
    .header-dec {
      strong {
        color: #1cabe2;
      }
    }

    ${breakpoint(`mobile`)} {
      padding-bottom: 72px;
    }
  }

  .process-ol {
    .list-item {
      width: 33.33%;
      margin-top: 96px;
      margin-bottom: 0;

      dd {
        padding-top: 16px;
        display: flex;
        flex-direction: column-reverse;

        h3 {
          margin: 0;
        }
      }
    }
    ${breakpoint(`mobile`)} {
      .list-item {
        width: 100%;
        margin-top: 0;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }

        dl {
          display: flex;
          align-items: center;

          dt {
            width: 80px;
          }
          dd {
            text-align: left;
            padding-top: 0;
            padding-left: 16px;

            h3 {
              text-align: left;
            }
          }
        }
      }
    }
  }
`;

const PanelContainer = styled.div`
  overflow-x: auto;
  .UiTabPanel {
    display: inline-flex;
    & > li {
      margin-right: 16px;
      padding: 0;

      &:last-child {
        margin-right: 0;
      }
    }

    ${breakpoint(`tablet`)} {
      & > li {
        margin-right: 8px;
      }
    }
  }
`;
const TabButton = styled(MainTabButton)<{ active: boolean }>`
  white-space: nowrap;
  border-color: ${(props) => (props.active ? `#1cabe2` : `#f8f9fa`)};
  background: ${(props) => (props.active ? `#1cabe2` : `#f8f9fa`)};
  color: ${(props) => (props.active ? `#fff` : `#2d2926`)};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    border-color: #1cabe2;
    background: #1cabe2;
  }
`;
const TabContainer = styled.div`
  padding-top: 64px;
`;
const TabContents = styled.div``;
const SectionTap = styled(Section)``;

const WhereWeWork: FC<PageProps> = ({ location }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabMenu = (id: number) => {
    setActiveTab(id);
  };

  return (
    <LayoutWithTitle
      location={location}
      title="활동 지역"
      description="for every child, everywhere"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  <span>모든 어린이가 행복한 나라</span>
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                유니세프는 <strong>190여 개 나라와 영토</strong>에서 모든
                어린이들, 특히 가장 소외된 어린이의 권리와 복지를 증진하기 위해
                일합니다. 전 세계 거의 모든 나라에서 활동하기 때문에 긴급구호
                상황에서 가장 신속하게 현장에 접근합니다. 재난 전에도, 재난
                중에도, 재난 후에도 한결같이 어린이 곁을 지킵니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <ProcessList itemData={countryData} />
        </Container>
      </SectionTop>

      <SectionTap className="by-sub-main-layout">
        <Container>
          <PanelContainer>
            <ul className="UiTabPanel">
              {tabTitle.map((title, idx) => (
                <li key={title}>
                  <TabButton
                    active={activeTab === idx}
                    onClick={() => handleTabMenu(idx)}
                  >
                    {title}
                  </TabButton>
                </li>
              ))}
            </ul>
          </PanelContainer>
          <TabContainer>
            <TabContents className="TabContents">{tab[activeTab]}</TabContents>
          </TabContainer>
        </Container>
      </SectionTap>
    </LayoutWithTitle>
  );
};
export default WhereWeWork;
